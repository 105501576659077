import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StateContext } from "../../store/faq/contexts"
import MinusSVG from "../../images/icons/minus.svg"
import PlusSVG from "../../images/icons/plus.svg"
import SanitizedHTML from "react-sanitized-html"

const QuestionBlock = styled.div`
  background: #fff;
  border: 1px solid #e1e2e3;
  box-sizing: border-box;
  padding: 0 6px;
  margin: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: ${(props) => (props.isOpen ? "0px" : "6px")};
  border-bottom-left-radius: ${(props) => (props.isOpen ? "0px" : "6px")};
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  padding: 0 10px;
  min-height: 50px;
  width: 100%;
`

const AnswerBlock = styled.div`
  background: #fafafa;
  box-sizing: border-box;
  color: #525151;
  height: ${(props) => (props.isOpen ? "auto" : "0px")};
  overflow: hidden;
  padding: ${(props) => (props.isOpen ? `12px 16px` : `0px 16px`)};
  font-size: 16px;
  line-height: 1.7;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;

  display: grid;

  margin-top: ${(props) => (props.isOpen ? `0px` : `-5px`)};
  transition: all 0.2s;

  p {
    margin: 0 0 0.3em 0;
  }
`

const HeaderText = styled.p`
  text-align: initial;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
`

const PlusMinus = styled.img`
  transition: all 0.2s;
  height: 20px;
  width: 20px;

  display: ${(props) => (props.display ? "block" : "none")};
`

export const SingleElement = ({ header, text }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div
      css={css`
        margin-bottom: 2em;
        font-family: "Rubik", Arial, Helvetica, sans-serif;
      `}
    >
      <QuestionBlock
        isOpen={isOpen}
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <HeaderText> {header} </HeaderText>

        <div>
          <PlusMinus display={isOpen} src={MinusSVG} />
          <PlusMinus display={!isOpen} src={PlusSVG} />
        </div>
      </QuestionBlock>

      <AnswerBlock isOpen={isOpen}>
        <SanitizedHTML html={text} />
      </AnswerBlock>
    </div>
  )
}
