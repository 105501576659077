import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import * as JsSearch from "js-search"
import { useInput, useFocus } from "react-hookedup"
import { StateContext } from "../../store/faq/contexts"
import SearchSVG from "../../images/icons/bx-search.svg"
import { orangeButtonColors } from "../shared"

const Wrapper = styled.div`
  max-width: 103em;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 60px;
  margin-bottom: 3em;
  transition: all 0.2s;
  box-shadow: ${(props) =>
    props.active
      ? "0px 2px 13px rgba(0, 0, 0, 0.08)"
      : "0px 0px 0px rgba(0, 0, 0, 0.08)"};
  margin: 0 auto 20px auto;
`

const ESearchInput = styled.input`
  border: 1px solid #e1e2e3;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #525151;
  padding: 0 16px;
  margin: 0;

  ::placeholder {
    color: #525151;
  }
`

const ESearch = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 72px;
  right: 0;
  border-radius: 6px;
  height: 100%;

  img {
    height: 18px;
  }

  ${orangeButtonColors}
`
const b = new JsSearch.Search("header")

b.addIndex("header")
b.addIndex("text")

export const Search = () => {
  const searchInput = useInput("")
  const searchFocus = useFocus()
  const [myJsSearch] = useState(b)

  const { state, dispatch } = useContext(StateContext)
  const { originalObjects, userText } = state

  useEffect(() => {
    myJsSearch.addDocuments(originalObjects)
  }, [])

  const search = () => {
    let found = []

    found = myJsSearch.search(searchInput.value)

    dispatch({ type: "SET_FOUND", payload: found })
  }

  useEffect(() => {
    dispatch({ type: "SET_SEARCH_VALUE", payload: searchInput.value })

    search()
  }, [searchInput.value])

  return (
    <div
      css={css`
        padding: 0 2em;
      `}
    >
      <Wrapper active={searchFocus.focused}>
        <ESearchInput
          placeholder="Введіть питання, ключові слова"
          {...searchInput.bindToInput}
          onFocus={() => {
            searchFocus.bind.onFocus()
          }}
          onBlur={() => {
            searchFocus.bind.onBlur()
          }}
        />

        <div
          css={css`
            position: relative;
          `}
        >
          <ESearch>
            <img src={SearchSVG} />
          </ESearch>
        </div>
      </Wrapper>
    </div>
  )
}
