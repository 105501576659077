import React, { useReducer } from "react"
import { css } from "@emotion/react"
import appReducer from "../store/faq/reducers"
import { StateContext } from "../store/faq/contexts"
import { Search } from "../components/faq/Search"
import { Result } from "../components/faq/Result"

const a1 = `
    <ul>
      <li>
        На наших курсах ми розпочинаємо вивчення JavaScript та React, тому
        потрібно мати впевнені знання в комп’ютерних науках, алгоритмах та у
        верстці - HTML, CSS
      </li>
      <li>
        Англійська B1 - тобі обов’язково знадобиться для читання документації та
        рекомендаційної літератури
      </li>
      <li>Наявність ПК, часу, бажання вчитись та зростати професійно</li>
    </ul>
  `

const a2 = `
    <p>
      Кожному користувачеві буде наданий доступ до бази знань, яка складається
      із навчальних відео (72 відео, 720 хвилин) та 30 практичних завдання. Дана
      база знань буде розміщена у форматі проекту на GitLab, і розбита на 12
      навчальних тижнів (milestones).
    </p>
    <p>
      При придбанні групового формату відвідування курсів (пакет "Навчання у групі із ментором") кожного
      тижня потрібно самостійно переглянути відео та рекомендаційний матеріал, і
      виконати поставлені практичні завдання. Після виконання завдань технічний
      консультант оглядає їх і формує рекомендації, зауваження (code review).
    </p>
    <p>
      На щотижневій груповій зустрічі можна буде поставити запитання по пройденому
      матеріалу, а також ментор розгляне найчастіші помилки/хороші рішення
      попереднього тижня.
    </p>
  `
const a3 = `
    <p>
      Проходження навчання максимально наближено до реальної роботи. Ти не лише
      отримаєш знання, а й навики роботи в команді над проектом.
    </p>
  `

const a4 = `
    <p>
      Запис на цей пакет обмежено локацією м.Тернопіль (та область) та
      Івано-Франківськ (та область).
    </p>
  `

const a5 = `
    <ul>
      <li>
        <p>Студент залишає заявку для вступу на курс </p>
        <p>
          Далі для визначення рівня підготовки потрібно пройти тест по базових
          знаннях
        </p>
      </li>
      <li>Невеличке тестове завдання</li>
      <li>Ознайомча співбесіда</li>
    </ul>
  `

const a6 = `
    <p>Після оплати пакету "Навчання у групі із ментором" ти можеш зробити запит на повернення коштів. Запит можна подати до першого модуля - 14 днів після старту курсу. Запит на рефанд просимо подавати на мейл <a href="mailto:academy@apiko.com">academy@apiko.com</a>
    </p>
  `

const a7 = `
    <p>Оплата за пакет відбувається на платформі Udemy.</p>
  `

const a8 = `
    <p>
      Оплата пакету відбувається після здачі тестового завдання. Для запису на
      курс потрібно заповнити заявку, натиснувши кнопку "Записатись на курс".
    </p>
  `

const a9 = `
    <p>Ціна на курс залежить від обраного пакету.</p>
  `

const a10 = `
    <p>
      Безкоштовний пакет передбачає доступ до YouTube теки із 100+ навчальних
      відео.
    </p>
    <p>
      Пакет "Самостійний" надає студенту доступ до бази лекцій та завдань, а
      також, доступ до slack спільноти курсів.
    </p>
    <p>Пакет "Навчання у групі із ментором" включає в себе:</p>

    <ul>
      <li>Доступ до бази знань (відео + завдання)</li>
      <li>Доступ до slack спільноти курсів</li>
      <li>Код рев’ю протягом тривалості курсу</li>
      <li>Slack канал групи (25-30 учасників)</li>
      <li>Щотижневі групові заняття з ментором</li>
    </ul>
  `

const a11 = `
    <p>
      Якщо ти вмотивований навчатись у нас і на даний час ти студент одного із
      ВУЗів, напиши нам пошту <a href="mailto:academy@apiko.com">academy@apiko.com</a>
    </p>
  `

const a12 = `
    <p>
     Ні, ми повністю оновили курси, змінивши його програму: записано нові навчальні відео та практичні завдання.
    </p>
    <p>
Цього разу ми вирішили кожну із підтем висвітлити окремим відео тривалістю 5-15 хвилин.
    </p>
  `

const initialState = {
  originalObjects: [
    {
      header: "Які знання потрібні для запису на курс?",
      text: a1,
    },
    {
      header: "Як проходить онлайн навчання із ментором?",
      text: a2,
    },

    {
      header: `Яка перевага пакету "Навчання у групі із ментором"?`,
      text: a3,
    },
    {
      header: `Чи є обмеження для придбання пакету "Навчання у групі із ментором"?`,
      text: a4,
    },
    {
      header: `Як проходить вступ на навчання учасникам пакету "Навчання у групі із ментором"?`,
      text: a5,
    },

    {
      header: "Чи можу я зробити рефанд після оплати курсу?",
      text: a6,
    },
    {
      header: `Як оплатити пакет "Самостійний"?`,
      text: a7,
    },
    {
      header: `Як оплатити пакет "Навчання у групі із ментором"?`,
      text: a8,
    },
    {
      header: "Від чого залежить вартість курсу?",
      text: a9,
    },
    {
      header: "Чим відрізняються пакети учасника?",
      text: a10,
    },

    {
      header: `Як отримати знижку на платний пакет "Навчання у групі із ментором"?`,
      text: a11,
    },

    {
      header: `Чи у навчальних матеріалах будуть використані відео із попередніх курсів?`,
      text: a12,
    },
  ],
  userText: "",
  foundObjects: [],
  asideFilter: null,
}

const  Faq = ({ noSearch = false }) => {
  const [state, dispatch] = useReducer(appReducer, initialState)

  return (
    <>
      <StateContext.Provider value={{ state, dispatch }}>
        {!noSearch && (
          <>
            <Search />
            <div
              css={css`
                max-width: 107em;
                padding: 0 2em;
                margin: auto;
                min-height: 60vh;
              `}
            >
              <Result />
            </div>
          </>
        )}

        {noSearch && <Result />}
      </StateContext.Provider>
    </>
  )
}

export default Faq
