import React, { useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StateContext } from "../../store/faq/contexts"
import { SingleElement } from "./SingleElement"
import NotFOundSVG from "../../images/not-found.svg"
import { SharedDivider } from "../shared"

const EList = styled.div`
  display: flex;
  flex-direction: column;
`

export const Result = ({ noSearch = false }) => {
  const { state, dispatch } = useContext(StateContext)
  const { userText, foundObjects, originalObjects } = state

  if (foundObjects.length > 0) {
    return (
      <div>
        <EList>
          {foundObjects.map((i) => (
            <SingleElement
              header={i.header}
              text={i.text}
              key={`${i.header}elist-single-elementfoundobj`}
            />
          ))}
        </EList>

        <SharedDivider data-count={originalObjects.length - foundObjects.length} />
      </div>
    )
  } else if (foundObjects.length === 0 && !!userText) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          p {
            color: #525151;
            margin: 0;
            font-size: 16px;
            font-family: "Rubik", Arial, Helvetica, sans-serif;
            margin-top: 1em;
          }
        `}
      >
        <img
          src={NotFOundSVG}
          css={css`
            max-width: 300px;
            margin-top: 3em;
            margin-bottom: 4em;
          `}
        />

        <p>По вашому запиту нічого не було знайдено.</p>
        <p> Спробуйте ще раз :)</p>
      </div>
    )
  } else {
    return (
      <EList>
        {originalObjects.map((i, index) => (
          <SingleElement
            header={i.header}
            text={i.text}
            key={`${i.header}elist-single-elementdefaultobj`}
          />
        ))}
      </EList>
    )
  }
}
