const userTextReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_VALUE":
      return action.payload

    default:
      return state
  }
}

const foundObjectsReducer = (state, action) => {
  switch (action.type) {
    case "SET_FOUND":
      return action.payload

    default:
      return state
  }
}

const originalObjectsReducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default function appReducer(state, action) {
  return {
    userText: userTextReducer(state.userText, action),
    foundObjects: foundObjectsReducer(state.foundObjects, action),
    originalObjects: originalObjectsReducer(state.originalObjects, action),
  }
}
