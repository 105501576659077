import React  from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { 
  SharedDividerHalf,
  SharedH3,
} from "../components/shared"
import Faq from "../templates/faq"

const Wrapper = styled.div`
  margin: auto;
  max-width: 106em;
  padding: 0 2em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

export default () => {
  return (
    <>
      <SEO />
      <Helmet title="FAQ">
        <html lang="uk" />
      </Helmet>

      <Header />

      <SharedDividerHalf />

      <Wrapper>
        <SharedH3
          as="h1"
          css={css`
            color: #111;
            padding: 0;
            margin: 0;
            text-align: initial;
          `}
        >
          FAQ
        </SharedH3>

        <p
          css={css`
            color: #8f96a1;
            font-weight: 400;
            font-size: 16px;
            margin: 1em 0 0 0;
            line-height: 2;
          `}
        >
          Всі питання та відповіді, які вас можуть цікавити
        </p>
      </Wrapper>

      <SharedDividerHalf />

      <Faq />

      <Footer />
    </>
  )
}
